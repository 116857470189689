export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'background',
      padding: ['0.5rem', '0.5rem', '0.5rem', '1rem']
    },
    '.containerScrolled': {
      backgroundColor: 'black',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static'],
        '.container': {
          // padding: ['0.5rem'],
        },

        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      padding: '0rem',

      '.logo': {
        img: {
          filter: 'unset',
          maxHeight: ['80px', '80px', '80px', '80px']
        }
      },
      '.logoScrolled ': {
        img: {
          filter: 'unset',
          maxHeight: ['60px', '60px', '60px', '60px'],
          height: 'unset'
        }
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          color: 'primary',
          fontWeight: 'bold',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          color: 'primary',

          padding: '0.5rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      border: 'none',
      height: '45px',
      div: {
        backgroundColor: 'primary'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      width: ['85%', '60%', '50%', '40%'],
      color: 'white',
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '100px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.navItem': {
        a: {
          fontSize: ['0.9rem', '1rem', '1.25rem', '1.25rem']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'white',
          svg: {
            path: { fill: 'white' }
          }
        },
        '.socialContainer': {
          flexDirection: ['', '', '', 'row']
        }
      }
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    maxWidth: 'unset',
    padding: ['3rem 1rem 7rem', '', '2rem 5rem 2rem'],
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 5px',
    borderColor: 'primary',
    // '.multiButtonContainer': {
    //   a: {
    //     variant: 'buttons.primary',
    //   },
    // },
    '.gonationLogo': {
      width: 'fit-content'
    },
    backgroundColor: 'black'
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'primary',
    order: '1'
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'body',
    textTransform: 'capitalize'
  },
  text: {
    textAlign: 'center',
    lineHeight: '1.75',
    fontSize: '0.85rem',
    order: '3',
    p: {
      lineHeight: '1.75',
      letterSpacing: '0.5px',
      textAlign: 'left',
      fontSize: 'inherit'
    },
    'p:last-child': {
      margin: '0rem'
    }
  },

  // borders: {
  //   '::before': {
  //     content: "''",
  //     backgroundImage:
  //       'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
  //     backgroundSize: '50px',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'center center',
  //     margin: ['0rem 0rem 1.5rem', '', '0rem 0rem 3rem'],
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     paddingBottom: '1rem',
  //     height: '50px',
  //     width: '100%',
  //     borderBottom: '2px dotted',
  //     borderColor: 'primary',
  //   },

  //   '::after': {
  //     content: "''",
  //     order: '6',
  //     backgroundImage:
  //       'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
  //     backgroundSize: '50px',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'center center',
  //     margin: '3rem 0rem 0rem',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     paddingBottom: '1rem',
  //     height: '50px',
  //     width: '100%',
  //     borderTop: '2px dotted',
  //     borderColor: 'primary',
  //   },
  // },

  sideBySide1: {
    flexWrap: 'wrap',
    padding: ['2rem 0.5rem 2rem 3rem', '2rem 1rem 2rem 3rem', '3rem', '4rem'],
    '.lazyload-wrapper': {
      border: 'solid 5px',
      borderColor: 'primary'
    },
    '.content': {
      padding: ['2rem 0.5rem 2rem 2.5rem', ' 2.5rem', '2.5rem', '3rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      px: 4,
      py: 2
    }
  },

  centerBlock: {
    '.section': {
      variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem 0rem 0rem auto',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start',
      borderLeft: '5px solid #928776'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: '3rem',
    margin: '0rem auto -4.5rem',
    width: ['calc(100% - 2rem)', '', 'calc(100% - 9rem)'],
    border: 'solid 11px',
    borderColor: 'primary',
    '.section': {
      margin: 'auto auto',
      // display: 'none',
      padding: '0.5rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      color: 'white',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      color: '#d7a85a'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '::after': {
      content: "'Scroll'",
      width: '0.25rem',
      textTransform: 'uppercase',
      fontSize: '0.9rem',
      textShadow: '1px 1px 10px black',
      borderLeft: 'solid 2px',
      borderColor: 'primary',
      padding: '0rem 1rem 3rem',
      wordWrap: 'break-word',
      whiteSpace: 'break-spaces',
      display: 'inline',
      bottom: ['3rem', '5rem'],
      left: ['20%', '10%'],
      position: 'absolute',
      letterSpacing: '1px'
    }
  },

  homepageHeroShout: {},

  aboutPage: {
    variant: 'customVariants.sideBySideV4',
    img: {
      objectFit: 'contain'
    },
    '.title, .text p': {
      color: 'white'
    },
    '.lazyload-wrapper': {
      alignItems: 'center'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    borderColor: 'primary',
    borderBottom: '5px solid'
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    borderTop: '5px solid',
    borderColor: 'primary'
  },

  homepageReservation: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['3rem 1rem', '', '5rem 1rem'],
    flexDirection: 'column',
    backgroundColor: 'black',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    borderBottom: '5px solid',
    borderColor: 'primary'
  },

  homepageContact: {
    variant: 'customVariants.centerBlock',
    '.section': {
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem auto 0rem 0rem',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start',

      borderRight: ['', '', '5px solid #928776'],
      borderLeft: ['5px solid #928776', '', 'none']
    }
  },

  homepageShout: {
    '.shoutPopUpButton': {
      width: ['', '', '3rem'],
      height: ['', '', '3rem']
    },
    '.text': {
      marginBottom: '0rem'
    },
    '.date': {
      textAlign: 'left'
    },
    '.containerPopUp': {
      border: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuTab': { color: 'white' }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'background',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '1rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.4rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  hoursOnly: {
    order: '3',
    justifyContent: 'center',
    '.content_container': {
      padding: '0rem',
      '> h3': {
        display: 'none'
      }
    },
    '.title': {
      //   display: 'none'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem',
      display: 'none'
    },
    '.locationmap, iframe': {
      display: 'none'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ====================
  // ? === reservations ===
  // ? ====================

  reservationPageWidget: {
    variant: 'customVariants.homepageReservation',
    marginTop: ['100px', '', '', '116px'],
    backgroundColor: '#161515',
    minHeight: ['70vh', '', '90vh'],
    'h3.title': {
      textAlign: 'center'
    }
  }
}
